import tinyEditor from '../tinymce/tinymce-editor.vue';
import stageCard from '../stage-card/stage-card.vue';
import defaultEmailAddresses from '@/components/default-email/default-email.vue';
import { mapState } from 'vuex';
import graphQLUtilities from '../../services/graphQLUtilities/graphQLUtilities';
import bffUserServices from '../../services/bff/bffUserServices';
import dateUtilities from '../../services/dateUtilities/dateUtilities';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import store from '@/store/store';

export default {
  components: { defaultEmailAddresses, tinyEditor, stageCard },
  props: ['editable', 'updating', 'templateLoading', 'cancel'],
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('templates', ['currentTemplate']),
    ...mapState('templates', ['currentTemplateVersions']),
    ...mapState('templates', ['isLoading']),
    ...mapState('applications', ['isApplicationLoading']),
    nameErrors() {
      const errors = [];
      this.isNameInvalid = false;
      if (!this.$v.currentTemplate.name.$dirty) return errors;
      !this.$v.currentTemplate.name.required && errors.push('Template Name is required');
      if (errors.length) {
        this.isNameInvalid = true;
      }
      this.setValidationFlag();
      return errors;
    },
    subjectErrors() {
      const errors = [];
      this.isSubjectInvalid = false;
      if (!this.$v.currentTemplate.subject.$dirty) return errors;
      !this.$v.currentTemplate.subject.required && errors.push('Email subject is required');

      // This limit is based off of RFC 2822 for internet message format
      // http://www.faqs.org/rfcs/rfc2822.html
      !this.$v.currentTemplate.subject.maxLength && errors.push('Email subject must be max of 998 characters');
      if (errors.length) {
        this.isSubjectInvalid = true;
      }
      this.setValidationFlag();
      return errors;
    },
    fromAddressErrors() {
      const errors = [];
      this.isEmailInvalid = false;
      if (!this.$v.currentTemplate.defaultEmailAddresses.from.$dirty) return errors;
      !this.$v.currentTemplate.defaultEmailAddresses.from.required && errors.push('From Email Address is required');
      !this.$v.currentTemplate.defaultEmailAddresses.from.email && errors.push('You must enter a valid from email address');
      if (errors.length) {
        this.isEmailInvalid = true;
      }
      this.setValidationFlag();
      return errors;
    },
    devSelected: function() {
      return this.devTemplate.versionId === this.currentTemplate.versionId;
    },
    testSelected: function() {
      return this.testTemplate.versionId === this.currentTemplate.versionId;
    },
    betaSelected: function() {
      return this.betaTemplate.versionId === this.currentTemplate.versionId;
    },
    prodSelected: function() {
      return this.prodTemplate.versionId === this.currentTemplate.versionId;
    },
    promoteButtonText() {
      if (this.prodSelected) {
        return 'Promote';
      }
      if (this.betaSelected) {
        return 'Promote to Prod';
      }
      if (this.testSelected) {
        return 'Promote to Beta';
      }
      if (this.devSelected) {
        return 'Promote to Test';
      }
      return 'Promote to Dev';
    }
  },
  data() {
    return {
      application: {},
      applicationId: '',
      name: '',
      description: '',
      fromAddress: '',
      trackingPixel: false,
      doNotSendListCheck: false,
      isResendable: false,
      editorContent: '',
      body: '',
      fieldEnvironment: ['dev', 'test', 'beta', 'prod'],
      subject: '',
      currentTemplateVersion: '',
      selectedVersion: {},
      defaultAddress: null,
      loading: false,
      expansionPanels: ['Stage Status', 'Details', 'Default Email Addresses', 'Body'],
      devTemplate: {},
      testTemplate: {},
      betaTemplate: {},
      prodTemplate: {},
      expandedPanels: [0, 1, 3],
      promoting: false,
      loadEditor: false,
      stagesLoaded: false,
      isNameInvalid: false,
      isSubjectInvalid: false,
      isEmailInvalid: false
    };
  },
  validations: {
    currentTemplate: {
      name: { required },
      subject: { required, maxLength: maxLength(998) },
      defaultEmailAddresses: { from: { required, email } }
    }
  },
  watch: {
    selectedVersion: async function(val) {
      for (let i in this.currentTemplateVersions) {
        if (val === this.currentTemplateVersions[i].formattedVersionTime) {
          this.$router.push({ name: 'template', params: { id: this.$route.params.id, templateId: this.$route.params.templateId, versionId: this.currentTemplateVersions[i].versionId } });
        }
      }
    },
    $route: {
      handler: async function() {
        await this.refreshDetails(true);
      },
      immediate: true
    }
  },
  methods: {
    setValidationFlag() {
      if (this.isEmailInvalid || this.isSubjectInvalid || this.isNameInvalid) {
        this.$emit('validationCheck', true);
      } else if (!this.isEmailInvalid && !this.isSubjectInvalid && !this.isNameInvalid) {
        this.$emit('validationCheck', false);
      }
    },
    async fetchTemplateStages() {
      this.stagesLoaded = false;

      if (this.currentTemplateVersions.length > 0) {
        const commonIds = this.currentTemplateVersions.flatMap((x) => x.stages).map((x) => x.userId);

        let users = [];

        if (commonIds.length > 0) {
          const uniqueCommonIds = [...new Set(commonIds)];

          const response = await bffUserServices.getUsersByCommonIds(uniqueCommonIds);

          users = response.data.data;
        }

        for (let i = 0; i < this.currentTemplateVersions.length; i++) {
          const templateVersion = this.currentTemplateVersions[i];
          const stages = ['dev', 'test', 'beta', 'prod'];

          stages.forEach(stageName => {
            const stage = templateVersion.stages.find(x => x.stage === stageName);
            if (stage) {
              const template = { ...templateVersion };
              template.stage = stage;
              template.stage.user = users.find(x => x.commonId === template.stage.userId);
              template.stage.promotedDateTime = dateUtilities.formatDateTimeToString(template.stage.promotedDateTime, true);
              this[`${stageName}Template`] = template;
            }
          });
        }
      }

      if (Object.keys(this.devTemplate).length < 1) {
        this.devTemplate = { formattedDateTime: 'No Template promoted to dev', stage: { userId: '', stage: 'dev', promotedDateTime: '' } };
      }
      if (Object.keys(this.testTemplate).length < 1) {
        this.testTemplate = { formattedDateTime: 'No Template promoted to test', stage: { userId: '', stage: 'test', promotedDateTime: '' } };
      }
      if (Object.keys(this.betaTemplate).length < 1) {
        this.betaTemplate = { formattedDateTime: 'No Template promoted to beta', stage: { userId: '', stage: 'beta', promotedDateTime: '' } };
      }
      if (Object.keys(this.prodTemplate).length < 1) {
        this.prodTemplate = { formattedDateTime: 'No Template promoted to prod', stage: { userId: '', stage: 'prod', promotedDateTime: '' } };
      }
      this.stagesLoaded = true;
    },

    togglePanel(panelArrayPosition) {
      if (this.expandedPanels.includes(panelArrayPosition)) {
        this.expandedPanels.splice(this.expandedPanels.indexOf(panelArrayPosition), 1);
      } else {
        this.expandedPanels.push(panelArrayPosition);
      }
    },
    assignDefaultEmailAddressed(newAddresses) {
      this.currentTemplate.defaultEmailAddresses = newAddresses;
    },
    async selectStageCard(template) {
      if (template.id) {
        if (template.versionId !== this.currentTemplate.versionId) {
          this.$router.push({ name: 'template', params: { id: this.$route.params.id, templateId: this.$route.params.templateId, versionId: template.versionId } });
        } else {
          this.$snotify.info('Template is currently selected.');
        }
      } else {
        this.$snotify.error('No Template Promoted for that Stage');
      }
    },

    async promoteCurrentVersion() {
      try {
        this.promoting = true;
        const response = await graphQLUtilities.promoteTemplate(this.currentTemplate.id, this.currentTemplate.versionId, this.user.commonid, this.user.subteam);
        if (response.StatusCode === 200) {
          this.$emit('refreshTemplateList');
          this.$snotify.success(response.Message);
          await this.refreshDetails();
          this.promoting = false;
        } else {
          this.$snotify.error(response.Message);
          this.promoting = false;
        }
      } catch (err) {
        this.$snotify.error('A server error occured while promoting the template.');
        this.promoting = false;
      }
    },
    async refreshDetails(showLoading = false) {
      this.loading = showLoading;
      await store.dispatch('templates/getAllTemplateVersions', { applicationId: this.$route.params.id, templateId: this.$route.params.templateId });
      this.selectVersion(this.$route.params.versionId);
      await this.fetchTemplateStages();
      this.loading = false;
    },
    selectVersion(versionId) {
      this.selectedVersion = null;
      for (let i = 0; i < this.currentTemplateVersions.length; i++) {
        if (this.currentTemplateVersions[i].versionId === versionId) {
          this.selectedVersion = this.currentTemplateVersions[i];
        }
      }
      if (!this.selectedVersion) {
        this.selectedVersion = this.currentTemplateVersions[0];
      }
    },
    bodyRecieved(value) {
      this.$emit('updateBody', value);
    }
  },
  async mounted() {
    this.selectedVersion = null;
  }
};
