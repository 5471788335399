import templateDetail from '@/components/template-detail/template-detail.vue';
import emailHistory from '@/components/emailHistory/emailHistory.vue';
import { mapState } from 'vuex';
import dateUtils from '@/services/dateUtilities/dateUtilities';
import graphQLUtilities from '../../services/graphQLUtilities/graphQLUtilities';
import bffUserServices from '@/services/bff/bffUserServices';
import store from '@/store/store';

export default {
  components: { templateDetail, emailHistory },
  computed: {
    ...mapState('templates', ['currentTemplate']),
    ...mapState('applications', ['currentApplication']),
    ...mapState('auth', ['user'])
  },
  props: ['templateId'],
  data() {
    return {
      formInvalid: false,
      editable: false,
      loading: true,
      updating: false,
      creatorLoading: true,
      showHistory: false,
      processing: false,
      creator: {
        FirstName: '',
        LastName: ''
      },
      friendlyDate: '',
      historyToggle: false,
      updatedBody: '',
      isAdmin: false,
      deleteDialog: false,
      readOnly: true
    };
  },
  async mounted() {
    this.loading = true;
    if (this.$route.params.id !== this.currentApplication.id) {
      await store.dispatch('applications/getApplication', { userId: this.user.commonid, applicationId: this.$route.params.id });
    }

    this.loading = false;
  },
  watch: {
    $route: {
      handler: async function() {
        await this.refreshTemplateList(false);
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.$store.state.templates.currentTemplate = {};
  },
  methods: {
    editableSwitch() {
      this.editable = !this.editable;
    },
    async refreshTemplateList(shouldUpdate = true) {
      this.updating = shouldUpdate;
      await this.loadTemplateData();
      this.updating = false;
    },
    async saveTemplate() {
      try {
        this.processing = true;
        this.loading = true;
        this.currentTemplate.body = this.updatedBody ? this.updatedBody : this.currentTemplate.body;
        this.currentTemplate.userId = this.user.commonid;
        await store.dispatch('templates/updateTemplate', this.currentTemplate);
        this.editable = !this.editable;

        this.$router.push({ name: 'template', params: { id: this.$route.params.id, templateId: this.$route.params.templateId, versionId: this.currentTemplate.versionId } });
        this.$snotify.success('Template Updated');
      } catch (err) {
        this.$snotify.error('Template Not Updated.');
      }
      this.processing = false;
      this.loading = false;
    },
    async loadTemplateData() {
      await store.dispatch('templates/getTemplateVersion', { applicationId: this.$route.params.id, templateId: this.$route.params.templateId, versionId: this.$route.params.versionId });
      if (this.currentTemplate) {
        this.creatorLoading = true;
        const response = await bffUserServices.getUsersByCommonIds([this.currentTemplate.createdBy]);
        this.creator = response.data.data[0];
        this.friendlyDate = dateUtils.formatDateTimeToString(this.currentTemplate.createdDateTime, true);
        this.creatorLoading = false;
      }
    },
    updateBody(value) {
      this.updatedBody = value;
    },
    async deleteTemplate() {
      try {
        this.updating = true;
        await graphQLUtilities.deleteTemplate(this.currentTemplate.id);
        this.$snotify.success('Template successfully deleted.');
        this.$router.push({ name: 'application', params: { id: this.$route.params.id } });
        this.updating = false;
        this.deleteDialog = false;
      } catch (e) {
        this.$snotify.error('Template was not deleted successfully.');
        this.updating = false;
        this.deleteDialog = false;
      }
    }
  }
};
