import store from '@/store/store';

export default {
  async deleteTemplate(templateId) {
    let query = `mutation { deleteTemplate (id: "${templateId}") {
      Name
    }}`;
    await store.dispatch('templates/deleteTemplate', { query: query });
  },
  async promoteTemplate(templateId, versionId, userId, userTeam) {
    let query =
    `mutation {promoteTemplate(id: "${templateId}", versionId: "${versionId}", userId: "${userId}", teamName: "${userTeam}") }`;
    return store.dispatch('templates/promoteTemplate', {
      query: query
    });
  }
};
